// import Slick Slider JS
import "slick-carousel";
import jQuery from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import(/* webpackPrefetch: true */ "../../utils/numberCount.js");

jQuery(document).ready(function ($) {
  //start the slider
  if ($(".main-slider").length > 0) {
    $(".main-slider").slick({
      lazyLoad: "progressive",
      cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
    });
  }
  if ($(".servicePrice").length > 0) {
    $(".servicePrice").on("click", function (e) {
      e.preventDefault();
      $("#servicePrice").removeClass("d-none");
    });
    $(".btn-close, .modal_popup_overlay").on("click", function () {
      $("#servicePrice").addClass("d-none");
    });
  }
  if ($(".profileCapacity").length > 0) {
    $(".profileCapacity").on("click", function (e) {
      e.preventDefault();
      $("#profileCapacity").removeClass("d-none");
    });
    $(".btn-close, .modal_popup_overlay").on("click", function () {
      $("#profileCapacity").addClass("d-none");
    });
  }
  if ($(window).width() < 992) {
    //home slider
    $(".homeHouseList_main.slider").slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 481,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 361,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  } else {
    if ($(".project_slider").length > 0) {
      $(".project_slider").slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
      });
    }
  }
  //---------------------------------------------
  if ($(".why_to_choose_lists").length > 0 && $(window).width() < 992) {
    $(".why_to_choose_lists ul").slick({
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }
});
